<template>
    <div class="admin-view" v-loading="mainLoading">
        <el-container style="height: 100%">
            <el-header>
                <el-button class="back-btn" @click="handleBack()">
                    <i class="iconfont icon30_huaban1fuben75"></i>返回
                </el-button>
                <router-link
                    class="tab-btn"
                    v-for="item in navs"
                    :key="item.name"
                    :to="item.path"
                >
                    {{ item.name }}
                </router-link>
            </el-header>
            <el-container class="content">
                <router-view></router-view>
            </el-container>
        </el-container>
    </div>
</template>

<script >
let backUrl = '';
export default {
    name: 'admin',
    beforeRouteEnter(to, from, next) {
        backUrl = from.fullPath;
        next();
    },
    data() {
        return {
            mainLoading: false,
            navs: [{
                path: '/admin/menu',
                name: '菜单绑定',
            }, {
                path: '/admin/org',
                name: '组织机构',
            }, {
                path: '/admin/processManage',
                name: '流程管理',
            }, {
                path: '/admin/globalSettings',
                name: '全局设置',
            }, {
                path: '/admin/dataDictionary',
                name: '数据字典',
            }],
            backUrl: '',
        };
    },
    created() {
        const tenantCategoryCode = this.$takeTokenParameters('TenantCategoryCode') || '';
        if (tenantCategoryCode === '10') {
            this.navs.push({
                path: '/admin/tenantControlSettings',
                name: '租户管控设置',
            });
        }
        const currentRouter = this.$route.path;
        if (currentRouter === '/admin') {
            this.$router.push('/admin/menu');
        }
    },
    methods: {
        handleBack() {
            if (backUrl.indexOf('admin') > -1) {
                const routes = this.$store.state.antRouter;
                this.$router.push({ path: routes[0].redirect });
            } else {
                this.$router.push({ path: backUrl });
            }
        },
    },
};
</script>

<style lang="stylus" scoped >
    .admin-view
        width 100%
        height calc(100vh - 0.8rem);
        background #E1E6EC
        .el-header
            width: 100%;
            height: 0.8rem;
            padding 0 0.2rem !important
            background: #FFFFFF;
            display: flex;
            align-items: center;
            .back-btn
                display flex
                align-items center
                justify-content center
                width 1rem
                height 0.4rem
                color #1577D2
                font-size 0.18rem
                font-weight 500
                .icon30_huaban1fuben75
                    font-size 0.2rem;
                    margin-right 0.03rem
            .tab-btn
                display flex
                align-items center
                justify-content center
                height: 0.4rem;
                background: #EDF2F9
                border-radius: 0.02rem;
                color  #333333
                font-size 0.18rem
                margin 0 0.1rem;
                padding 0 0.1rem
                cursor pointer
                border 1px solid #CACACA
                &.router-link-active
                    background: #1577D2;
                    border 0;
                    color #fff
        .content
            width 100%
            height: calc(100% - 0.6rem);
</style>
